<template>
  <div class="mainBody">
    <div class="realData">
      <div class="topSearch">
        <div class="tabsBox" ref="other">
          <div class="tabs" @click="handleClick('')">
            <div class="tab" :class="{ 'actie': pageParams.installType == '' }">全部</div>
          </div>
          <div class="tabs" @click="handleClick(2)">
            <div class="tab" :class="{ 'actie': pageParams.installType == 2 }">管网</div>
          </div>
          <div class="tabs" @click="handleClick(1)">
            <div class="tab" :class="{ 'actie': pageParams.installType == 1 }">场站</div>
          </div>
        </div>
        <div class="search">
          <el-form :inline="true" :model="pageParams" class="demo-form-inline" label-position="right">
            <el-form-item label="编码">
              <el-input v-model="pageParams.equipmentCode" clearable class="search-input" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="名称">
              <el-input v-model="pageParams.equipmentName" clearable class="search-input" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="pageParams.equipmentStatus" clearable filterable placeholder="请选择">
                <el-option v-for="item in equipmentStatus" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div class="search-q">
                <el-button type="primary" @click="search">查询</el-button>
                <el-button type="info" plain @click="resetSearch">重置</el-button>
                <el-button type="primary" @click="addItem" icon="el-icon-plus">新增</el-button>
              </div>
            </el-form-item>
          </el-form>

        </div>
      </div>
      <div class="content" :style="{ height: height }">
        <div class="listBox">
          <template v-if="list && list.length > 0">
            <div class="list" v-for="(item, index) in list" :key="index">
              <div class="title">
                编码：{{item.equipmentCode}}
              </div>
              <div class="main">
                <div class="main-list">
                  <div class="lable">报警器名称：</div>
                  <div class="test">{{item.equipmentName}}</div>
                </div>
                <div class="main-list">
                  <div class="lable">规格：</div>
                  <div class="test">{{item.spec}}</div>
                </div>
                <div class="main-list">
                  <div class="lable">型号：</div>
                  <div class="test">{{item.mode}}</div>
                </div>
                <div class="main-list">
                  <div class="lable">厂家：</div>
                  <div class="test">{{item.factory}}</div>
                </div>
                <div class="main-list">
                  <div class="lable">添加日期：</div>
                  <div class="test">{{item.installDate}}</div>
                </div>
              </div>
              <div class="btnBox">
                <div class="btn" @click="editItem(item)">编辑</div>
                <template v-if="item.equipmentStatus != 2">
                <div class="shu"></div>
                <div class="btn" @click="deleteItem(item)">删除</div>
                <div class="shu"></div>
                <div class="btn" @click="switchItem(item)">{{item.equipmentStatus==1 ? '停用' : '启用'}}</div>
                </template>
                <template v-if="item.equipmentStatus == 2">
                <div class="shu"></div>
                <div class="btn" style="color: #bcbec2;cursor: not-allowed;">故障</div>
                </template>
                <div class="shu"></div>
                <div class="btn" @click="checkDialog(item)">监测点位</div>
              </div>
            </div>
          </template>
          <template v-else>
            <div :style="{width:'100%',textAlign:'center',display: 'grid',placeItems: 'center',height:height}">
              暂无设备
            </div>
          </template>
        </div>
        <el-pagination background layout="total,sizes,prev, pager, next" :page-size="pageParams.size"
          :current-page="pageParams.current" :page-sizes="[ 30, 50, 100]" :total="total"
          @size-change="handleSizeChange" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
    <!-- 新增/编辑报警器 -->
    <el-dialog :visible.sync="showoperate" :close-on-click-modal="false" width="970px" class="Dialogform">
        <template slot="title">
            <div class="dialogTitles">
                <span></span> <b>{{ popeName }}</b>
            </div>
        </template>
        <el-form :inline="true" :model="addData" :rules="rules" ref="addDataForm">
            <el-form-item label="报警器名称" label-position="top" prop="equipmentName">
              <el-input v-model="addData.equipmentName" clearable class="search-input" placeholder="请输入" size="mini" maxlength="30"></el-input>
            </el-form-item>
            <!-- <el-form-item label="报警器编码" label-position="top" prop="equipmentCode">
              <el-input v-model="addData.equipmentCode" clearable class="search-input" placeholder="请输入" size="mini" maxlength="30"></el-input>
            </el-form-item> -->
            <el-form-item label="安装类型" label-position="top" prop="installType">
              <el-select v-model="addData.installType" clearable filterable placeholder="请选择"  size="mini" @change="installTypeChange">
                  <el-option v-for="item in installType" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备唯一码">
                <el-input maxlength="100" v-model="addData.soleCode" placeholder="请输入" size="mini"></el-input>
            </el-form-item>
            <!-- 管网 -->
            <template v-if="addData.installType == 2">
              <el-form-item label="规格" label-position="top" prop="spec">
                <el-input v-model="addData.spec" clearable class="search-input" placeholder="请输入" size="mini" maxlength="30"></el-input>
              </el-form-item>
              <el-form-item label="型号" label-position="top" prop="mode">
                <el-input v-model="addData.mode" clearable class="search-input" placeholder="请输入" size="mini" maxlength="30"></el-input>
              </el-form-item>
              <el-form-item label="安装日期" label-position="top" prop="installDate">
                <el-date-picker
                  v-model="addData.installDate"
                  type="date"
                  size="mini"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="生产厂家" label-position="top" prop="factory">
                <el-input v-model="addData.factory" clearable class="search-input" placeholder="请输入" size="mini" maxlength="30"></el-input>
              </el-form-item>
              <el-form-item label="设备状态" label-position="top"  prop="equipmentStatus">
              <el-select v-model="addData.equipmentStatus" clearable filterable placeholder="请选择"  size="mini">
                  <el-option v-for="item in equipmentStatusdict" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
              </el-select>
              </el-form-item>
              <el-form-item label="详细地址" label-position="top" class="searchitem"  prop="address">
                <el-input v-model="addData.address" clearable class="search-input1" placeholder="请输入" size="mini" disabled maxlength="100"></el-input>
              </el-form-item>
              <!-- <mapDialog @closeModal="closeModel" ref="mapDialog"></mapDialog> -->
              <div style="height: 400px">
                <map-box ref="mapBox" @closeModal="closeModel"></map-box>
              </div>
            </template>
            <!-- 场站 -->
            <template v-if="addData.installType == 1">
              <el-form-item label="所属站点" label-position="top" prop="stationId">
                <el-select v-model="addData.stationId" clearable filterable placeholder="请选择"  size="mini">
                    <el-option v-for="item in stationList" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="规格" label-position="top" prop="spec">
                <el-input v-model="addData.spec" clearable class="search-input" placeholder="请输入" size="mini" maxlength="30"></el-input>
              </el-form-item>
              <el-form-item label="型号" label-position="top" prop="mode">
                <el-input v-model="addData.mode" clearable class="search-input" placeholder="请输入" size="mini" maxlength="30"></el-input>
              </el-form-item>
              <el-form-item label="安装日期" label-position="top" prop="installDate">
                <el-date-picker
                  v-model="addData.installDate"
                  type="date"
                  size="mini"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="生产厂家" label-position="top" prop="factory">
                <el-input v-model="addData.factory" clearable class="search-input" placeholder="请输入" size="mini" maxlength="30"></el-input>
              </el-form-item>
              <el-form-item label="设备状态" label-position="top" prop="equipmentStatus">
                <el-select v-model="addData.equipmentStatus" clearable filterable placeholder="请选择"  size="mini">
                    <el-option v-for="item in equipmentStatusdict" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                </el-select>  
              </el-form-item>
            </template>
        </el-form>
        <div class="search-q">
            <el-button type="primary" @click="addsubmit" size="mini">确认</el-button>
            <el-button type="info" plain @click="reset" size="mini">重置</el-button>
        </div>
    </el-dialog>
    <!-- 已绑定监测点位 -->
    <el-dialog :visible.sync="showalready" :close-on-click-modal="false" width="970px">
      <template slot="title">
          <div class="dialogTitles">
              <span></span> <b>已绑定监测点位</b>
          </div>
      </template>
      <div class="search-q">
        <el-button type="primary" @click="addbound" icon="el-icon-plus" size="mini" style="margin-bottom:10px">新增</el-button>
      </div>
      <el-table :height="500" border :data="alreadylist" style="width: 100%;" align='center'>
          <el-table-column label="序号" type="index" width="60"  align='center'> </el-table-column>
          <el-table-column prop="pointName" label="点位名称"> </el-table-column>
          <el-table-column prop="pointCode" label="点位编号"> </el-table-column>
          <el-table-column prop="dataTypeName" label="数据类型"> </el-table-column>
          <el-table-column prop="description" label="监测位置描述"> </el-table-column>
          <el-table-column prop="collectPointName" label="远传采集点">
            <template slot-scope="{row}">
              <div>{{row.collectPointName ? row.collectPointName : '-'}}</div>  
            </template>
          </el-table-column>
          <el-table-column prop="operation" label="操作">
            <template slot-scope="{ row }">
              <el-button type="text" size="mini" @click="unbindItem(row)">解绑</el-button>
            </template>
          </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 监测点列表 -->
    <el-dialog :visible.sync="shownotbound" :close-on-click-modal="false" width="970px" class="bounddialog">
      <template slot="title">
          <div class="dialogTitles">
              <span></span> <b>监测点列表</b>
          </div>
      </template>
      <el-form :inline="true" :model="boundParams" class="demo-form-inline" label-position="right">
        <el-form-item label="监测点名称">
          <el-input v-model="boundParams.pointName" clearable class="search-input" placeholder="请输入"  size="mini" maxlength="30"></el-input>
        </el-form-item>
        <el-form-item label="数据类型">
          <el-select v-model="boundParams.dataType" clearable filterable placeholder="请选择" size="mini">
            <el-option v-for="item in dataTypeDict" :key="item.type" :label="item.name"
                                :value="item.type"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="boundsearch(2,'notboundList')"  size="mini">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- <el-table  border :data="notboundList" style="width: 100%;" tooltip-effect="dark" @selection-change="handleSelectionChange"> -->
        <!-- highlight-current-row @current-change="handleCurrentChange" -->
      <el-table :height="500" border :data="notboundList" style="width: 100%;" >
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column label="序号" type="index" width="60"  align='center'> </el-table-column>
          <el-table-column prop="pointName" label="点位名称"> </el-table-column>
          <el-table-column prop="pointCode" label="点位编号"> </el-table-column>
          <el-table-column prop="dataTypeName" label="数据类型"> </el-table-column>
          <el-table-column prop="description" label="监测位置描述"> </el-table-column>
          <el-table-column prop="collectPointName" label="远传采集点">
            <template slot-scope="{row}">
              <div>{{row.collectPointName ? row.collectPointName : '-'}}</div>  
            </template>
          </el-table-column>
           <el-table-column label="操作" width="70">
          <template slot-scope="{ row }">
            <el-radio v-model="selectVal" :label="row">&nbsp;</el-radio>
          </template>
        </el-table-column>
      </el-table>
      <div class="search-q">
        <el-button type="info" plain @click="shownotbound = false" size="mini">取消</el-button>
        <el-button type="primary" @click="sunmitbound" size="mini">添加</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mapDialog from "./mapDialog";
import { mapActions, mapState } from 'vuex'
import scadaApi from "@/apis/scadaApi";
import mapBox from './mapBox.vue'
import {  getMapCenterLocation } from '@/apis/commonType'
export default {
  name: 'realData',
  components: {mapDialog,mapBox},
  data() {
    return {
      pageParams: {
        current: 1,
        size: 100,
        equipmentType:4,
        installType:''
      },
      list: [],
      total: 0,
      height: 0,
      showoperate:false,
      popeName:'新增',
      addData:{
        installType:1,
        address:'',
        equipmentType:4,
        installDate:""
      },
      showalready:false,
      alreadylist:[],
      shownotbound:false,
      notboundList:[],
      boundParams:{},
      equipmentStatusdict:[],
      stationList:[],
      rules: {
        equipmentName:[{ required: true, message: '请填写报警器名称', trigger: 'blur' }],
        // equipmentCode:[{ required: true, message: '请填写报警器编码', trigger: 'blur' }],
        installType:[{ required: true, message: '请选择安装类型', trigger: 'change'  }],
        stationId:[{ required: true, message: '请选择所属站点', trigger: 'change'  }],
        spec:[{ required: true, message: '请填写规格', trigger: 'blur' }],
        mode:[{ required: true, message: '请填写型号', trigger: 'blur' }],
        installDate:[{  required: true, message: '请选择日期', trigger: 'change' }],
        factory:[{ required: true, message: '请填写生产厂家', trigger: 'blur' }],
        equipmentStatus:[{ required: true, message: '请选择设备状态', trigger: 'change'  }],
        address:[{ required: true, message: '请选择地址', trigger: ['change','blur']  }],
      },
      eqId:'',
      monitorPointIds:'',
      scadaStationIds:'',
      selectVal: null,
    }
  },
  computed: {
      ...mapState('dict', ['equipmentStatus','installType','dataTypeDict'])
  },
  mounted() {
    this.getDropdown('equipmentStatus,installType')
    this.$nextTick(() => {
      this.height = `calc(100% - ${this.$refs.other.scrollHeight}px)`
    })
    this.getlist()
  },
  methods: {
    ...mapActions('dict', ['getDropdown','getDict1']),
    /**
     * 选择分类
     */
    handleClick(e) {
      if (this.pageParams.installType != e) {
        this.pageParams.installType = e
      }
      this.search()
    },
    /**
     * 获取列表数据
     */
    getlist(){
      scadaApi.getEquipmentLists(this.pageParams).then(res => {
        if(res.code == 200) {
          this.list = res.data.list
          this.total = res.data.total
        }
      })
    },
    /**
     * 查询
     */
    search(){
      this.pageParams.current = 1
      this.getlist()
    },
    /**
     * 重置查询
     */
    resetSearch(){
      this.pageParams= {
        current: 1,
        size: 100,
        equipmentType:4,
        installType:''
      },
      this.getlist()
    },
    /**
     * 重置
     */
    reset(){
      if(this.addData.installType == 2){
        this.$refs.mapDialog.cancelAddress()
      }
      this.addData={
        installType:1,
        address:'',
        equipmentType:4,
        installDate:""
      }
      // 给默认时间
      let date = new Date();
      let year = date.getFullYear();
      let month = (date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1);
      let day = (date.getDate()) < 10 ? '0'+(date.getDate()) : (date.getDate());
      // this.addData.installDate = year+'-'+month+'-'+day
      this.$set(this.addData,'installDate',year+'-'+month+'-'+day)
      if(this.$refs.addDataForm){
        this.$refs.addDataForm.resetFields();
      }
    },
    /**
     * 获取场站
     */
    getStationList(){
      scadaApi.getStationOptions().then(res => {
        if(res.code == 200) {
          this.stationList = res.data
        }
      })
    },
    /**
     * 新增（弹窗）
     */
    addItem(){
      
      this.equipmentStatusdict = [...this.equipmentStatus]
      this.equipmentStatusdict.forEach((el,index) => {
        if(el.value == 2) {
          this.equipmentStatusdict.splice(index,1)
        }
      });
      // 场站下拉
      this.getStationList()
      this.addData = {
        installType:1,
        address:'',
        equipmentType:4
      }
      // 给默认时间
      let date = new Date();
      let year = date.getFullYear();
      let month = (date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1);
      let day = (date.getDate()) < 10 ? '0'+(date.getDate()) : (date.getDate());
      // this.addData.installDate = year+'-'+month+'-'+day
      this.$set(this.addData,'installDate',year+'-'+month+'-'+day)
      this.popeName = '新增'
      this.showoperate = true
      if(this.$refs.addDataForm){
        this.$refs.addDataForm.resetFields();
      }
    },
    installTypeChange(e){
      this.addData.address = ''
      this.addData.point = null
      if(e == 2) {
        getMapCenterLocation().then(res => {
          if(res.data!=null&&res.data.length>0){
            let area = res.data.split(',')
            let lonlat = "POINT("+area[0]+' '+area[1]+')'
            this.$refs.mapBox.setMap({},lonlat)
          }
        })
      }
    },
    /**
     * 新增/编辑（确认）
     */
    addsubmit(){
      let that = this
      if(this.addData.point && this.addData.point.indexOf('POINT') != -1) {
        this.addData.point = (this.addData.point).slice(6,-1)
      }
      this.$refs['addDataForm'].validate((valid) => {
          if (valid) {
            if(that.popeName == '新增') {
              scadaApi.addEquipmentData(that.addData).then(res=> {
                if(res.code == 200) {
                  that.$message.success('新增成功')
                  that.showoperate = false
                  that.search()
                }
              })
            } else {
              // 编辑
              scadaApi.addEquipmentupdate(that.addData).then(res=> {
                if(res.code == 200) {
                  that.$message.success('编辑成功')
                  that.showoperate = false
                  that.search()
                }
              })
            }
          }
      })
    },
    /**
     * 位置回显
     */
    closeModel(address, lnglat) {
      this.addData.address = address
      this.addData.point = lnglat.join(' ')
    },
    /**
     * 编辑(弹窗)
     */
    async editItem(item){
     
      // 状态（删除故障状态）
      this.equipmentStatusdict = [...this.equipmentStatus]
      this.equipmentStatusdict.forEach((el,index) => {
        if(el.value == 2) {
          this.equipmentStatusdict.splice(index,1)
        }
      });
      // 场站下拉
      await this.getStationList()
      this.popeName = '编辑'
      this.showoperate = true
      if(this.$refs.addDataForm){
        this.$refs.addDataForm.resetFields();
      }
      // 查询单条数据
      await scadaApi.getEquipmentfindByIds({id:item.id}).then(res => {
        if(res.code == 200) {
          this.addData = res.data
        }
      })
      
      if(this.addData.installType == 2) {
      this.$nextTick(() => {
            this.$refs.mapBox.setMap({},this.addData.point)
            setTimeout(() => {
            if(this.addData.point){
              let location = this.addData.pointArray[0]
              let locationdata = {
                location:{lng:location[0],lat:location[1]}
              }
              this.$refs.mapBox.SearchItemClick(locationdata)
            }
             },300)
          })
      }
    },
    /**
     * 删除
     */
    deleteItem(item){
      let that = this
      this.$confirm('操作将删除该报警器, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            scadaApi.getEquipmentDelete({id:item.id}).then(res => {
              if(res.code == 200) {
                that.search()
                that.$message.success('删除成功!');
              }
            })
            
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消操作'
            });          
          });
    },
    /**
     * 停用/启用
     */
    switchItem(item){
      let that = this
       this.$confirm(item.equipmentStatus == 0 ? '报警器将被启用':'报警器将被停用' +', 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data ={
            equipmentStatus:item.equipmentStatus == 0 ? 1 : 0,
            id:item.id
          }
          scadaApi.addEquipmentstatus(data).then(res => {
            if(res.code == 200) {
              that.$message.success(res.msg);
              that.getlist()
            }
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });
    },
    /**
     * 监测点
     */
    checkDialog(item){
      this.eqId = item.id
      this.scadaStationIds = item.stationId
      // eqBindStatus 1已绑定 2未绑定
      this.boundsearch(1,'alreadylist')
      this.showalready = true
    },
    /**
     * 解绑
     */
    unbindItem(row){
      this.$confirm('监测点将被解绑, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            equipmentId:null,
            monitorPointIds:row.id
          }
          scadaApi.setmonitorPoint(data).then(res => {
            if(res.code == 200) {
              this.$message.success('操作成功!');
              this.boundsearch(1,'alreadylist')
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });
    },
    /**
     * 新增监测点（弹窗）
     */
    addbound(){
      this.boundsearch(2,'notboundList')
      this.getDict1({parentCode:'300081'})
      this.shownotbound = true
      // 复选值制空
      this.monitorPointIds=''
      this.selectVal = null
    },
    /**
     * 监测点列表(查询)
     */
    boundsearch(val,name){
      let data = {
        eqId:val == 1 ? this.eqId : '',
        eqBindStatus:val,
        current:0,
        size:-1,
        dataFlag:'leakWarn',
        scadaStationId: val == 2 ? this.scadaStationIds : ''
      }
      data = {...data,...this.boundParams}
      scadaApi.addSelectLists(data).then(res => {
        if(res.code==200) {
          this[name] = res.data.list
        }
      })
    },
    /**
     * 监测点复选(废弃)
     */
    handleSelectionChange(e){
      console.log(e)
    },
    /**
     * 监测点单选
     */
    handleCurrentChange(e){
      if(e){
        this.monitorPointIds = e.id
      }
    },
    /**
     * 添加监测点
     */
    sunmitbound(){
      if (!this.selectVal) return this.$message.error('请选择采集点')
      let data = {
        equipmentId:this.eqId,
        monitorPointIds:this.selectVal.id
        // monitorPointIds:this.monitorPointIds
      }
      scadaApi.setmonitorPoint(data).then(res => {
        if(res.code == 200) {
          this.$message.success('操作成功!');
          this.shownotbound = false
          this.boundsearch(1,'alreadylist')
        }
      })
    },
    handleSizeChange(val) {
      this.pageParams.current = 1
      this.pageParams.size = val
      this.getlist()
    },
    currentChange(current) {
      this.pageParams.current = current
      this.getlist()
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .search {
  .el-form-item__content {
    line-height: 43px;
  }

  .el-form-item {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .el-form-item__label {
    color: #0F0F0F;
    padding-right: 5px;
    line-height: 43px;
  }

  .el-input__inner {
    height: 28px;
  }

  .el-input__suffix {
    top: -3px
  }
  .el-button{
    padding: 6px 10px;
  }
}

.mainBody {
  padding: 10px;
  height: calc(100vh - 175px);

  .realData {
    background: #fff;
    height: 100%;
    position: relative;

    .topSearch {
      border-bottom: 1px solid #E3E3E3;
      display: flex;
      justify-content: space-between;

      .tabsBox {
        display: flex;
        // justify-content: space-between;
        flex: 0 0 220px;

        .tabs {
          cursor: pointer;
          padding: 0 20px;

          .tab {
            height: 100%;
            box-sizing: border-box;
            border-bottom: 2px solid transparent;
            display: flex;
            align-items: center;

            &.actie {
              color: #1082FF;
              border-bottom: 2px solid #1082FF;
            }
          }
        }

      }
    }

    .content {
      width: 100%;
      // height: calc(100% - 45px);
      padding: 20px;
      padding-right: 0;
      box-sizing: border-box;
      background: #fff;

      .listBox {
        height: calc(100% - 40px);
        overflow: auto;

        // background: red;
        .list {
          float: left;
          width: 15.6%;
          margin-right: 15px;
          margin-bottom: 15px;
          background: #FFFFFF;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #E3E3E3;

          .title {
            height: 42px;
            line-height: 42px;
            background: #F6F6F6;
            padding: 0 15px;
            font-weight: bold;
            color: #303133;
          }

          .main {
            padding: 12px 16px;
            font-size: 13px;

            .main-list {
              display: flex;
              margin-bottom: 5px;

              &:last-child {
                margin-bottom: 0;
              }

              .lable {
                width: 90px;
                color: #727E97;
              }

              .test {
                color: #26334E;
                width:calc(100% - 70px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          .btnBox {
            height: 38px;
            background: #F6F6F6;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .shu {
              width: 1px;
              height: 20px;
              background: #E3E3E3;
            }

            .btn {
              flex: 1;
              display: flex;
              justify-content: center;
              color: #1082FF;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
::v-deep .bounddialog{
  .el-form-item {
    margin-bottom: 0;
    margin-right: 20px;
  }
  .search-q{
    text-align: right;
    margin-top: 10px;
  }
}
::v-deep .Dialogform{
  .el-form-item{
    margin-bottom:12px;
    width: 300px;
    .el-form-item__label{
      line-height: 22px;
    }
    .el-date-editor{
      // height: 28px;
      width: 300px;
    }
    .el-form-item__content{
      width: 300px;
    }
    .el-select{
      width: 300px;
    }
  }
  .el-form-item__error{
    padding-top: 0;
  }
  .searchitem{
    width: 100%;
    .el-form-item__content{
      width: 100%;
    }
  }
  .search-q{
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
}
</style>